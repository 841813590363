<template>
    <page>
        <page-head>
            <app-button property="success" icon="icon-add" @click="createModal">
                {{ translate('button.Add') }}
            </app-button>
        </page-head>

        <page-body>
            <data-grid
                :data-source="stores"
                :columns="columns"
                :action-column-width="120"
                :action-column-text="translate(translateKey + '.Label.Action')"
            >
                <div slot="actionSlot" slot-scope="list">
                    <app-button
                        v-if="can(permission + '.update')"
                        @click="createModal(list.row.form)"
                        :sm="true"
                        :title="translate('button.Edit')"
                        icon="icon-edit-2"
                        property="primary"
                    />

                    <app-button
                        v-if="can(permission + '.delete')"
                        :sm="true"
                        @click="remove(list.row.id)"
                        :title="translate('button.Delete')"
                        icon="icon-trash-2"
                        property="danger"
                    />

                </div>
            </data-grid>
        </page-body>

        <modal :id="modalId" size="sm">
            <modal-head>
                <modal-title>{{ currentPage.title }}</modal-title>
            </modal-head>
            <modal-body v-if="modelShow">
                <form @submit.prevent="save">
                    <grid>
                        <form-group
                            :label="translate(translateKey + '.Label.Department')"
                            name="department_id"
                        >
                            <form-tree-select :options="select_departments" v-model="form.department_id"/>
                        </form-group>

                        <form-group
                            :label="translate(translateKey + '.Label.Category')"
                            name="category_id"
                        >
                            <form-tree-select :options="select_categories" v-model="form.category_id"/>
                        </form-group>

                        <form-group
                            :label="translate(translateKey + '.Label.Name') + (appLanguages.length > 1 ? ' ('+lang.name+')' : '')"
                            :name="'translates.'+lang.code+'.name'"
                            v-for="(lang, index) in appLanguages"
                            :key="'name'+index"
                        >
                            <form-text v-model="form.translates[lang.code].name"/>
                        </form-group>

                        <form-group
                            :label="translate(translateKey + '.Label.Description') + (appLanguages.length > 1 ? ' ('+lang.description+')' : '')"
                            :name="'translates.'+lang.code+'.description'"
                            v-for="(lang, index) in appLanguages"
                            :key="'description'+index"
                        >
                            <form-html-editor v-model="form.translates[lang.code].description"/>
                        </form-group>

                        <form-group
                            :label="translate('Məhsulun qiyməti')"
                            name="price"
                        >
                            <form-text v-model="form.price" />
                        </form-group>

                        <form-group
                            :border="false"
                            :label="translate(translateKey + '.Label.Photos')"
                            name="photos"
                        >
                            <form-photos :col="3" path="store" :item-photos="photoItems" img-height="110px" v-model="form.photos"/>
                        </form-group>
                        <form-group
                            :label="translate('Məhsuldan anbarda varmı?')"
                            name="stock"
                        >
                            <form-tree-select :options="stock" displayExpr="name" v-model="form.stock"/>
                        </form-group>
                        <form-group
                            :label="translate('Məhsulun əlimizdəki sayı')"
                            name="stock_number"
                        >
                            <form-text v-model="form.stock_number" />
                        </form-group>

                        <app-button class="justify-center" property="success" type="submit">
                            {{ translate('button.Save') }}
                        </app-button>
                    </grid>
                </form>
            </modal-body>
        </modal>
    </page>
</template>

<script>
/*
 * Import Components
 * */
import {mapActions, mapState} from 'vuex';

const modalId = 'createModal';
const translateKey = 'crm.PortalStore';

export default {
    name: "PortalStoreIndex.vue",
    data() {
        return {
            translateKey,
            modalId,
            modelShow: false,
            columns: [
                {
                    caption: translateKey + '.Label.Name',
                    dataField: 'name',
                    show: true
                },
                {
                    caption: translateKey + '.Label.Price',
                    dataField: 'price',
                    show: true
                },
            ],
            stock: [
                {id:'yes', name: 'yes'},
                {id:'no', name: 'no'},               
            ],
            photoItems: [],
            form: {}
        }
    },
    computed: {
        ...mapState('DepartmentStore', ['select_departments']),
        ...mapState('StoresCategoriesStore', ['select_categories']),
        ...mapState('StoresStore', ['stores']),
        permission() {
            return this.currentPage.permission;
        }
    },
    methods: {
        ...mapActions('StoresStore', ['getStores','setStore', 'getStore', 'deleteStore']),
        ...mapActions('DepartmentStore', ['getSelectDepartments']),
        ...mapActions('StoresCategoriesStore', ['getSelectStoreCategories']),

        /*
         * Form Create
         * */
        formCreate(item = {}) {
            const form = {
                id: item.id || null,
                price: item.price || 0,
                stock: item.stock || null,
                stock_number: item.stock_number || 0,
                department_id: item.department_id || null,
                category_id: item.category_id || null,
                photos: item.photos && item.photos.length ? item.photos.map(i => i.name) : [],
                translates: {}
            }
            this.appLanguages.filter(i => {
                form.translates[i.code] = {
                    name: item.translates && item.translates[i.code] ? item.translates[i.code].name : null,
                    description: item.translates && item.translates[i.code] ? item.translates[i.code].description : null,
                }
            });
            this.photoItems = item.photos || [];
            this.form = form;
            this.modelShow = true;
        },
        /*
         * Create Modal
         * */
        createModal(item = {}) {
            this.modal(this.modalId)
            this.modelShow = false;
            this.resetError();
            this.formCreate(item);
        },
        /*
         * Remove
         * */
        remove(id) {
            this.alert().then(r => this.deleteStore(id).then(r => this.getStore()))
        },
        /*
         * Save
         * */
        save() {
            this.setStore(this.form)
            .then(r => {
                if (r) {
                    this.modal(this.modalId);
                    this.getStores();
                }
            })
        }
    },
    created() {
        this.getStores();
        this.getSelectDepartments();
        this.getSelectStoreCategories();
    }
}
</script>

<style scoped>

</style>
